import { FormEvent, useCallback, useEffect, useRef } from "react";
import { GridApi, IRowNode } from "ag-grid-community";

import { resizeRow } from "@components/Table/utils";

interface IUseCellAutoHeight<T> {
  node: IRowNode<T>;
  api: GridApi<T>;
  disabled?: boolean;
  resizeIfSmaller?: boolean;
  setValue?(value: string): void;
  isValid?(value: string): boolean;
}

const INVALID_CLASS = "ag-edit-focus-invalid";

export const useCellAutoHeight = <T>(params: IUseCellAutoHeight<T>) => {
  const { isValid, disabled, setValue, node, api, resizeIfSmaller } = params;
  const containerRef = useRef<HTMLDivElement>(null);

  const resizeContainerCallback = useCallback(
    (container: HTMLDivElement) => {
      resizeRow({ container, node, api, resizeIfSmaller, slack: 15 });
    },
    [api, node, resizeIfSmaller]
  );

  useEffect(() => {
    if (containerRef.current && !disabled) {
      resizeContainerCallback(containerRef.current);
    }
  }, [resizeContainerCallback, disabled]);

  const handleChange = (event: FormEvent<HTMLHeadingElement>) => {
    const newValue = (event.target as HTMLElement).innerText;

    if (containerRef.current) {
      if (isValid) {
        const validated = isValid(newValue);
        const classList = containerRef.current.parentElement?.classList;
        validated ? classList?.remove(INVALID_CLASS) : classList?.add(INVALID_CLASS);
      }
      resizeContainerCallback(containerRef.current);
    }

    setValue?.(newValue);
  };

  return { handleChange, containerRef };
};

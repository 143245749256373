import { ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Icon, IconName, Menu } from "@blueprintjs/core";
import { IHeaderParams } from "ag-grid-community";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { DeleteConfirmationDialog } from "@components/DeleteConfirmationDialog";
import { getStringActualColumnsConfig, parseGridColumnsToState } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import { PROPERTY_DATA_TYPE_ICONS_MAP } from "@components/Modeling/PropertyDefinition/constants";
import { Popover } from "@components/Popover";
import { getProjectStatusIcon } from "@components/ProjectManagement/constants";
import PropertyDefinitionMenu from "@components/Shared/PropertyDefinitionMenu/PropertyDefinitionMenu";
import { ESCAPE_KEY } from "@constants/keys";
import appStore from "@store/AppStore";
import { IPropertyDefinition } from "@store/PropertyDefinitionStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { getPluralOrSingularString } from "@utilities";

import { BLOCKS_TREE_COLUMN_ID } from "../constants";
import { NodeInfo } from "../types";

import HeaderAddNewNav from "./HeaderAddNewNav";
import HeaderStatusNav from "./HeaderStatusNav";

import styles from "./HeaderCell.module.scss";

export enum EHeaderType {
  ADD_NEW,
  CHECKBOX,
}

export interface IHeaderCellParams extends IHeaderParams<NodeInfo> {
  propertyDefinition?: IPropertyDefinition;
  statusDefinition?: IStatusDefinition;
  headerType?: EHeaderType;
  hideNav?: boolean;
}

const HeaderCell = (props: IHeaderCellParams) => {
  const { statusDefinition, propertyDefinition, headerType, api, column } = props;
  const [editMode, setEditMode] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const statusDefinitionProxy = useMemo(
    () => appStore.workspaceModel?.statusDefinitions.find(i => i.id === statusDefinition?.id),
    [statusDefinition]
  );

  const propertyDefinitionProxy = useMemo(
    () => appStore.workspaceModel?.propertyDefinitionMap.get(propertyDefinition?.id || ""),
    [propertyDefinition]
  );

  const { activeTableViewConfigId } = appStore.env;

  const activeTableViewConfig = useMemo(
    () => appStore.workspaceModel?.tableViewConfigs.get(activeTableViewConfigId),
    [activeTableViewConfigId]
  );

  const getHeaderName = useCallback(() => {
    if (statusDefinitionProxy) {
      return statusDefinitionProxy.label;
    } else if (propertyDefinitionProxy) {
      return propertyDefinitionProxy.label;
    }

    return "";
  }, [propertyDefinitionProxy, statusDefinitionProxy]);

  const handleHideColumn = () => {
    api.setColumnsVisible([column.getColId()], false);
    const columnsState = parseGridColumnsToState(api);
    const actualConfig = getStringActualColumnsConfig(columnsState);
    activeTableViewConfig?.setConfig(actualConfig);
  };

  const [inputValue, setInputValue] = useState<string>(getHeaderName());

  useEffect(() => setInputValue(getHeaderName()), [statusDefinition, propertyDefinition, getHeaderName]);

  const getHeaderNav = () => {
    let nav = null;

    if (props.hideNav) {
      return null;
    }

    if (statusDefinitionProxy) {
      nav = (
        <HeaderStatusNav
          {...(appStore.env.activeTableViewConfigId !== defaultTableViewConfigTabId && {
            onHide: handleHideColumn,
          })}
          onDelete={() => setShowConfirmationDialog(true)}
          statusDefinition={statusDefinitionProxy}
        />
      );
    } else if (propertyDefinitionProxy) {
      nav = (
        <PropertyDefinitionMenu
          {...(appStore.env.activeTableViewConfigId !== defaultTableViewConfigTabId && {
            onHide: handleHideColumn,
          })}
          onDelete={() => setShowConfirmationDialog(true)}
          propertyDefinition={propertyDefinitionProxy}
        />
      );
    } else {
      return null;
    }

    return (
      <Popover position="bottom-right" content={<Menu>{nav}</Menu>}>
        <Button minimal small icon={<Icon icon="drag-handle-vertical" size={12} />} e2eIdentifiers="menu" />
      </Popover>
    );
  };

  const getHeaderIcon = () => {
    let icon: string | undefined = undefined;

    if (statusDefinition) {
      icon = getProjectStatusIcon(statusDefinitionProxy?.type || StatusType.text);
    } else if (propertyDefinition) {
      icon = PROPERTY_DATA_TYPE_ICONS_MAP[propertyDefinition?.dataType];
    }

    return icon ? <Icon className="ag-header-icon" size={12} icon={icon as IconName} /> : null;
  };

  const handleTitleDoubleClick = () => {
    if (props.column.getColId() === BLOCKS_TREE_COLUMN_ID) {
      return;
    }

    setEditMode(true);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputBlur = () => {
    setEditMode(false);
    setInputValue(props.displayName);
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (!inputValue) {
        inputRef.current?.blur();
      } else {
        if (propertyDefinitionProxy) {
          propertyDefinitionProxy.setLabel(inputValue);
        } else if (statusDefinitionProxy) {
          statusDefinitionProxy.setLabel(inputValue);
        }
        setEditMode(false);
      }
    } else if (event.key === ESCAPE_KEY) {
      inputRef.current?.blur();
    }
  };

  const handleDeleteDefinition = () => {
    if (statusDefinition) {
      appStore.workspaceModel?.deleteStatusDefinition(statusDefinition);
    } else if (propertyDefinition) {
      appStore.workspaceModel?.deletePropertyDefinition(propertyDefinition);
    }
    setShowConfirmationDialog(false);
  };

  const renderViewMode = () => (
    <div className="ag-header-custom">
      {getHeaderIcon()}
      <div className="ag-header-custom--name" onDoubleClick={handleTitleDoubleClick}>
        {getHeaderName()}
      </div>
      {propertyDefinitionProxy && propertyDefinitionProxy?.autoRollupChildren && (
        <Icon className={styles.headerCellRollupIcon} size={12} icon="layout-hierarchy" />
      )}
      {getHeaderNav()}
    </div>
  );

  const renderEditMode = () => (
    <input
      ref={inputRef}
      onKeyUp={handleKeyUp}
      onBlur={handleInputBlur}
      onChange={handleInputChange}
      autoFocus
      className="ag-editable-input"
      type="text"
      value={inputValue}
    />
  );

  const getInstancesNumber = () => {
    if (statusDefinition) {
      return appStore.workspaceModel?.statusInstances.filter(s => s.statusDefinition?.id === statusDefinition.id).length || 0;
    } else if (propertyDefinition) {
      return appStore.workspaceModel?.propertyInstances.filter(s => s.propertyDefinition?.id === propertyDefinition.id).length || 0;
    }

    return 0;
  };

  const instancesNumber = getInstancesNumber();
  const definitionType = statusDefinition ? "status" : "property";
  const definitionLabel = statusDefinition ? statusDefinition.label : propertyDefinition?.label || "";
  const content = `Are you sure you want to delete this ${definitionType} definition? This action cannot be undone. ${
    instancesNumber ? `You are going to delete ${instancesNumber} ${getPluralOrSingularString(instancesNumber, "instances")}.` : ""
  }`;

  switch (headerType) {
    case EHeaderType.ADD_NEW:
      return (
        <div className="ag-header-custom">
          <Popover
            position="bottom-left"
            content={
              <Menu>
                <HeaderAddNewNav />
              </Menu>
            }
          >
            <Button
              className="ag-header-custom--add-new"
              minimal
              small
              icon={<Icon icon="plus" size={12} />}
              e2eIdentifiers="add-new-nav"
            />
          </Popover>
        </div>
      );
    default:
      return (
        <div className="w-full">
          {editMode ? renderEditMode() : renderViewMode()}
          <DeleteConfirmationDialog
            isOpen={showConfirmationDialog}
            titleItem={`${definitionType} definition: ${definitionLabel}`}
            description={content}
            onCancel={() => setShowConfirmationDialog(false)}
            onClose={() => setShowConfirmationDialog(false)}
            onConfirm={handleDeleteDefinition}
          />
        </div>
      );
  }
};

export default observer(HeaderCell);
